import React from "react";
import theme from "theme";
import { Theme, Text, Box, Section, Image, Link } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"about"} />
		<Helmet>
			<title>
				Школа водіння RoadReady
			</title>
			<meta name={"description"} content={"Твій шлях до впевненого водіння починається тут"} />
			<meta property={"og:title"} content={"Школа водіння RoadReady"} />
			<meta property={"og:description"} content={"Твій шлях до впевненого водіння починається тут"} />
			<meta property={"og:image"} content={"https://glarevista.com/img/1.png"} />
			<link rel={"shortcut icon"} href={"https://glarevista.com/img/1581912.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://glarevista.com/img/1581912.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://glarevista.com/img/1581912.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://glarevista.com/img/1581912.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://glarevista.com/img/1581912.png"} />
			<meta name={"msapplication-TileImage"} content={"https://glarevista.com/img/1581912.png"} />
		</Helmet>
		<Components.Header />
		<Section padding="140px 0" sm-padding="40px 0 40px 0" background="url(https://glarevista.com/img/1.jpg) center/cover">
			<Override slot="SectionContent" sm-align-items="center" />
			<Box max-width="800px" padding="50px 50px 80px 50px" background="--color-light" color="--dark">
				<Text as="h2" font="--headline2" margin="0 0 12px 0">
				Відкрийте для себе наш широкий спектр послуг
				</Text>
				<Text font="--base">
				У школі водіння RoadReady ми пишаємося тим, що пропонуємо комплексний набір послуг, розроблений для задоволення різноманітних потреб наших клієнтів. Від водіїв-початківців до тих, хто прагне вдосконалити свої навички, наші програми розроблені таким чином, щоб забезпечити найвищу якість навчання та підтримки.
				</Text>
			</Box>
		</Section>
		<Section
			padding="60px 0 60px 0"
			sm-padding="40px 0"
			min-height="600px"
			sm-min-height="auto"
			display="flex"
			flex-direction="column"
		>
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Text
				font="--base"
				text-transform="uppercase"
				letter-spacing="1px"
				max-width="850px"
				color="--grey"
				margin="0px"
				width="100%"
			>
				Комплексні послуги для водіїв-початківців
			</Text>
			<Text margin="8px 0px 32px" font="--headline2" color="--dark" width="100%">
			Про компанію та послуги
			</Text>
			<Text
				margin="0px"
				font="--base"
				color="--grey"
				width="25%"
				padding="0px 16px 0px 0px"
				md-width="50%"
				md-padding="0px 16px 0px 0px"
				md-margin="0px 0px 16px 0px"
				sm-width="100%"
				sm-padding="0px 0px 0px 0px"
			>
				Інструктори-експерти
				<br/><br/>
Сертифіковані професіонали<br/>
Наші викладачі мають високу кваліфікацію та сертифікати, що гарантує, що ви отримаєте першокласну освіту від досвідчених професіоналів. Вони навчені надавати персоналізовані інструкції, які відповідають вашим індивідуальним навчальним потребам.
<br/><br/>
Персоналізовані уроки<br/>
Кожен урок адаптований до вашого рівня володіння мовою та темпу навчання. Незалежно від того, чи ви новачок, чи потребуєте поглибленого навчання, наші інструктори проведуть вас через кожен крок з терпінням та досвідом.

			</Text>
			<Text
				margin="0px"
				font="--base"
				color="--grey"
				width="25%"
				padding="0px 16px 0px 16px"
				md-width="50%"
				md-padding="0px 0px 0px 16px"
				md-margin="0px 0px 16px 0px"
				sm-width="100%"
				sm-padding="0px 0px 0px 0px"
			>
				Курси водіїв-початківців
				<br/><br/>
Для тих, хто тільки починає водіння, наші курси для початківців охоплюють всі основи, необхідні для побудови міцного фундаменту.
				<br/>
Базові маневри
				<br/>
     Вивчіть основні навички водіння, такі як кермування, прискорення та гальмування.
     Опануйте основні маневри, включаючи повороти, паркування та зміну смуги руху.
				<br/><br/>
Правила дорожнього руху та дорожні знаки
				<br/>
     Розуміти правила дорожнього руху та закони про дорожній рух.
     Ознайомитися з поширеними дорожніми знаками та їх значенням.

			</Text>
			<Text
				margin="0px"
				font="--base"
				color="--grey"
				width="25%"
				padding="0px 16px 0px 16px"
				md-width="50%"
				md-padding="0px 16px 0px 0px"
				md-margin="0px 0px 32px 0px"
				sm-width="100%"
				sm-margin="0px 0px 16px 0px"
				sm-padding="0px 0px 0px 0px"
			>
				Просунуті техніки водіння
				<br/><br/>
Для водіїв, які бажають вдосконалити свої навички, наші просунуті курси пропонують більш поглиблене навчання.
				<br/>
Захисне водіння
				<br/>
     Вивчіть техніки передбачення та реагування на потенційні небезпеки.
     Вдосконалюйте свою здатність безпечно керувати автомобілем у різних умовах.
				<br/><br/>
Водіння на автомагістралі та в місті
				<br/>
     Набувайте впевненості за кермом на автомагістралях та в жвавому міському русі.
     Потренуйтеся проїжджати складні перехрестя та міські умови.

			</Text>
			<Text
				margin="0px"
				font="--base"
				color="--grey"
				width="25%"
				padding="0px 16px 0px 16px"
				md-width="50%"
				md-padding="0px 0px 0px 16px"
				md-margin="0px 0px 32px 0px"
				sm-width="100%"
				sm-padding="0px 0px 0px 0px"
			>
				Курси підвищення кваліфікації
				<br/><br/>
Ідеально підходять для тих, хто хоче вдосконалити свої навички водіння.
				<br/>
Оцінка навичок
				<br/>
      Оцініть свої поточні навички водіння та визначте сфери, які потребують вдосконалення.
      Отримайте персоналізований план для задоволення ваших конкретних потреб.
				<br/><br/>
Практичні заняття з водіння
				<br/>
      Візьміть участь у практичних заняттях з водіння, щоб відновити впевненість у собі.
      Зосередьтеся на таких аспектах, як паралельне паркування, перестроювання тощо.

			</Text>
		</Section>
		<Section padding="80px 0 80px 0" sm-padding="30px 0 30px 0" md-padding="40px 0 40px 0" quarkly-title="About-13">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" sm-min-width="280px" />
			<Box
				display="grid"
				grid-gap="32px"
				sm-grid-gap="16px"
				grid-template-columns="4fr 5fr"
				align-items="center"
				width="50%"
				lg-width="100%"
				lg-grid-template-columns="repeat(2, 1fr)"
			>
				<Image
					src="https://glarevista.com/img/8.jpg"
					object-fit="cover"
					width="100%"
					height="80%"
					lg-height="100%"
				/>
				<Image
					src="https://glarevista.com/img/9.jpg"
					object-fit="cover"
					width="100%"
					height="100%"
					max-height="460px"
				/>
			</Box>
			<Box
				display="flex"
				width="50%"
				flex-direction="column"
				justify-content="center"
				align-items="flex-start"
				lg-align-items="center"
				padding="42px 42px 42px 42px"
				lg-width="100%"
				md-padding="24px 24px 24px 24px"
				md-margin="24px 0px 0px 0px"
				sm-align-items="flex-start"
				sm-padding="24px 24px 24px 0px"
			>
				<Text
					margin="0px 0px 24px 0px"
					color="--dark"
					font="--headline3"
					lg-text-align="center"
					sm-text-align="left"
				>
					Зв'яжіться з нами, щоб дізнатися більше
				</Text>
				<Text
					margin="0px 0px 40px 0px"
					color="--greyD2"
					font="--base"
					lg-text-align="center"
					sm-text-align="left"
				>
					Це лише короткий огляд того, що ми пропонуємо в автошколі RoadReady. Щоб дізнатися про повний спектр наших послуг і про те, як ми можемо допомогти вам стати впевненим водієм, зв'яжіться з нами сьогодні або відвідайте нас особисто. Ми готові відповісти на всі ваші запитання та допомогти підібрати ідеальний курс, який відповідатиме вашим потребам. Не зволікайте, зв'яжіться з нами та зробіть перший крок до оволодіння дорогою.
				</Text>
				<Link
					href="/contact"
					color="--lightD1"
					text-decoration-line="initial"
					padding="11px 24px 12px 24px"
					background="--color-primary"
					font="--base"
					transition="--opacityOut"
					hover-opacity=".7"
					border-radius="8px"
					letter-spacing="1px"
				>
					Контакти
				</Link>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65ccedc78e2e8e0021782120"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});